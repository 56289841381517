/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { appWithTranslation, useTranslation } from 'next-i18next';
import Script from 'next/head';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Provider } from 'react-redux';
import { changeLocale } from '../components/common/general';
import store from '../store';
import LayoutContextContainer from '../components/contexts/LayoutContextContainer';

import '../styles/globals.css';
import '../styles/modal.css';
import '../styles/wheel.css';
import '../styles/responsive.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/elements.css';
import '../styles/grid.css';
// import 'antd/es/message/style/index.css';
// import 'antd/es/badge/style/index.css';
// import 'antd/es/tooltip/style/index.css';
import '../styles/StrengthMeter.css';
import '../styles/animated.css';

function MyApp({ Component, pageProps }) {
  const { t } = useTranslation('common');
  const router = useRouter();
  const locale = changeLocale(router.locale);

  return (
    <>
      <Script
        id='gtag-base'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${'GTM-NW4XLT2X'}');

          `,
        }}
      />
      <Head>
        <title>{t('meta.title')}</title>
        {/* <meta name='viewport' content='width=device-width, user-scalable=no' /> */}
        <meta name='description' content={t('meta.description')} />
        <meta name='keywords' content={t('meta.keywords')} />
        <meta name='image' content={`${process.env.SITE_URL}/metaImageBackground.jpg`} />
        <meta property='og:image' content={`${process.env.SITE_URL}/metaImageBackground.jpg`} />
        <meta property='og:image:secure_url' content={`${process.env.SITE_URL}/metaImageBackground.jpg`} />

        <meta
          name='p:domain_verify'
          content='2e7eadf8d6c143b58885b3e1f48b895d'
        />

        <meta httpEquiv='Content-Security-Policy' content='upgrade-insecure-requests' />

        <meta property='og:title' content={t('meta.title')} />
        <meta property='og:description' content={t('meta.description')} />

        <meta property='og:url' content={process.env.ENDPOINT} />

        <meta property='og:type' content='website' />

        <meta name='robots' content='all' />

        <link rel='icon' href='/favicon.ico' />

      </Head>

      <Provider store={store}>
        <LayoutContextContainer>
          <Component {...pageProps} />
        </LayoutContextContainer>
      </Provider>
    </>
  );
}

export async function getServerSideProps(context) {
  const { query, locale } = context;
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      query,
      branchId: process.env.BRANCH_ID,
    },
  };
}

export default appWithTranslation(MyApp);
